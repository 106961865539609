<template>
  <b-sidebar
    id="add-new-classification-sidebar"
    :visible="isAddNewClassificationSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    width="500"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-classification-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('classification.AddNew.newClassification') }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Request Type field -->
          <validation-provider
            #default="{ errors }"
            name="Tipo solicitud"
            rules="required"
          >
            <b-form-group
              :label="$t('classification.AddNew.typeRequestClassification')"
              :state="errors.length > 0 ? false:null"
            >
              <v-select
                v-model="classificationData.clasificacionSolicitudTipoId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                label="texto"
                :options="requestTypes"
                :reduce="option => option.id"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Customer field -->
          <validation-provider
            #default="{ errors }"
            name="Cliente"
            rules="required"
          >
            <b-form-group
              :label="$t('classification.edit.columns.customer')"
              :state="errors.length > 0 ? false:null"
            >
              <v-select
                v-model="classificationData.clienteId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                label="texto"
                :options="customers"
                :reduce="option => option.id"
                @close="
                  refetchVendors(classificationData.clienteId),
                  refecthParts(classificationData.clienteId)
                "
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!--Vendor field-->
          <b-form-group
            v-if="classificationData.clasificacionSolicitudTipoId === 1"
            :label="$t('classification.edit.columns.supplier')"
          >
            <v-select
              v-model="classificationData.proveedorId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              label="texto"
              :options="vendors"
              :reduce="option => option.id"
            />
          </b-form-group>
          <!--Part number field-->
          <validation-provider
            v-if="classificationData.clasificacionSolicitudTipoId === 1"
            #default="validationContext"
            name="Numero de parte"
            rules="required"
          >
            <b-form-group
              :label="$t('classification.edit.columns.numberPart')"
            >
              <b-form-input
                v-model="classificationData.numeroParte"
                :state="getValidationState(validationContext) && !partAlreadyExists"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <b-form-invalid-feedback :state="!partAlreadyExists">
                {{ $t('classification.edit.warningMessageNumber') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!--Part Edit field -->
          <validation-provider
            v-if="classificationData.clasificacionSolicitudTipoId === 2"
            #default="{ errors }"
            name="Numero de parte"
            rules="required"
          >
            <b-form-group
              :label="$t('classification.edit.columns.numberPart')"
              :state="errors.length > 0 ? false:null"
            >
              <v-select
                v-model="classificationData.parteEditarId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                label="texto"
                :options="parts"
                :reduce="option => option.id"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!--Unit of measurement field -->
          <validation-provider
            v-if="classificationData.clasificacionSolicitudTipoId === 1"
            #default="{ errors }"
            name="Unidad medida"
            rules="required"
          >
            <b-form-group
              :label="$t('classification.edit.columns.unitMeasure')"
              :state="errors.length > 0 ? false:null"
            >
              <v-select
                v-model="classificationData.unidadMedidaId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                label="texto"
                :options="unidadesMedida"
                :reduce="option => option.id"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!--Operation Type field--->
          <validation-provider
            v-if="classificationData.clasificacionSolicitudTipoId === 1"
            #default="{ errors }"
            name="Tipo de operacion"
            rules="required"
          >
            <b-form-group
              :label="$t('classification.AddNew.operationType')"
            >
              <v-select
                v-model="classificationData.operacionTipoId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="texto"
                :options="operationTypes"
                :reduce="option => option.id"
                @close="refetchDocumentTypes(classificationData.operacionTipoId)"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>

          <!--DocumentType field--->
          <validation-provider
            v-if="classificationData.clasificacionSolicitudTipoId === 1"
            #default="{ errors }"
            name="Clave de documento"
            rules="required"
          >
            <b-form-group
              :label="$t('classification.AddNew.documentType')"
            >
              <v-select
                v-model="classificationData.documentoClaveId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="texto"
                :options="documentTypes"
                :reduce="option => option.id"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>

          <!--Material type field--->
          <b-form-group
            v-if="classificationData.clasificacionSolicitudTipoId === 1"
            :label="$t('classification.AddNew.materialType')"
          >
            <v-select
              v-model="classificationData.parteMaterialTipoId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="texto"
              :options="materialTypes"
              :reduce="option => option.id"
            />
          </b-form-group>
          <validation-provider
            #default="validationContext"
            name="Observaciones"
            rules="required"
          >
            <b-form-group
              :label="$t('classification.AddNew.observations')"
            >
              <b-form-textarea
                v-model="classificationData.observacionesSolictud"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- <b-form-group
            v-if="classificationData.clasificacionSolicitudTipoId === 2"
            :label="$t('classification.list.isImmexProgram')"
          >
            <b-form-checkbox
              v-model="classificationData.esPogramaImmex"
            />
          </b-form-group> -->
          <b-form-group
            :label="$t('classification.list.uploadAttachments')"
          >
            <file-uploader
              v-model="classificationData.archivoSeleccionado"
              :file-name.sync="classificationData.archivoSeleccionadoNombre"
              @on-upload-file="onAddFile"
            />
          </b-form-group>
          <b-table
            :items="classificationData.solicitudArchivos"
            :fields="filesTableFields"
          >
            <template
              #cell(actions)="data"
            >
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click="onDeleteFile(data.item.guid)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">{{ $t('Lists.Delete') }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <b-alert
            v-if="classificationData.solicitudArchivos && classificationData.solicitudArchivos.length === 0"
            v-height-fade
            show
            fade
            class="p-1"
            variant="warning"
          >
            {{ $t('classification.AddNew.dataSheetRequired') }}
          </b-alert>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="addingClassification"
            >
              <b-spinner
                v-if="addingClassification"
                small
                class="mr-1"
              />
              {{ $t('Lists.Add') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              :disabled="addingClassification"
              @click="hide"
            >
              {{ $t('Lists.Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormTextarea,
  BButton,
  BFormInvalidFeedback,
  BFormInput,
  BTable,
  BDropdown,
  BDropdownItem,
  BSpinner,
  BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  required,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import optionService from '@/services/option.service'
import partService from '@/services/part.service'
import classificationService from '@/services/classification.service'
import i18n from '@/libs/i18n'
import FileUploader from '@/components/FileUploader.vue'
import { heightFade } from 'vue-ripple-directive'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormTextarea,
    BButton,
    BFormInvalidFeedback,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    FileUploader,
    BSpinner,
    BAlert,
  },
  directives: {
    heightFade,
  },
  props: {
    isAddNewClassificationSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    /* Services */
    const {
      fetchCustomers,
      fetchVendors,
      fetchUnidadesMedida,
      fetchOperationTypes,
      fetchDocumentTypes,
      fetchPartMaterialTypes,
      fetchClassificationRequestTypes,
      fetchParts,
    } = optionService()
    const {
      createClassification,
    } = classificationService()
    const {
      fetchPartExists,
    } = partService()
    /* UI */
    const addingClassification = ref(false)
    /* Data */
    const blankClassificationData = {
      clienteId: null,
      proveedorId: null,
      observacionesSolicitud: '',
      // guidHojaDatos: '',
      archivoSeleccionado: '',
      archivoSeleccionadoNombre: '',
      solicitudArchivos: [],
    }

    const filesTableFields = [
      {
        key: 'nombre',
        label: i18n.t('classification.edit.labelArchive'),
        sortable: false,
      },
      {
        key: 'actions',
        label: '',
        sortable: false,
      },
    ]

    const classificationData = ref(JSON.parse(JSON.stringify(blankClassificationData)))
    const resetClassificationData = () => {
      classificationData.value = JSON.parse(JSON.stringify(blankClassificationData))
    }

    const customers = ref([])
    fetchCustomers(data => {
      customers.value = data
    })

    const vendors = ref([])
    const refetchVendors = clienteId => {
      if (clienteId) {
        fetchVendors(data => {
          vendors.value = data
        }, clienteId)
      }
    }
    const unidadesMedida = ref([])
    fetchUnidadesMedida(data => {
      unidadesMedida.value = data
    })

    const operationTypes = ref([])
    fetchOperationTypes(data => {
      operationTypes.value = data
    })

    const documentTypes = ref([])
    const refetchDocumentTypes = operationTypeId => {
      fetchDocumentTypes(operationTypeId, data => {
        documentTypes.value = data
      })
    }

    const materialTypes = ref([])
    fetchPartMaterialTypes(data => {
      materialTypes.value = data
    })

    const requestTypes = ref([])
    fetchClassificationRequestTypes(data => {
      requestTypes.value = data
    })

    const parts = ref([])
    const refecthParts = clienteId => {
      if (clienteId) {
        fetchParts(clienteId, data => {
          parts.value = data
        })
      }
    }

    const partAlreadyExists = ref(false)
    /* Events */
    const onSubmit = () => {
      fetchPartExists(classificationData.value.clienteId, classificationData.value.numeroParte, response => {
        partAlreadyExists.value = response
        if (!partAlreadyExists.value && classificationData.value.solicitudArchivos.length > 0) {
          addingClassification.value = true
          createClassification(classificationData.value)
            .then(() => {
              emit('update:is-add-new-classification-sidebar-active', false)
              emit('refresh-data')
              addingClassification.value = false
            })
        }
      })
    }

    const onAddFile = () => {
      classificationData.value.solicitudArchivos.push(
        {
          guid: classificationData.value.archivoSeleccionado,
          nombre: classificationData.value.archivoSeleccionadoNombre,
        },
      )

      classificationData.value.archivoSeleccionado = ''
      classificationData.value.archivoSeleccionadoNombre = ''
    }
    const onDeleteFile = guid => {
      classificationData.value.solicitudArchivos = classificationData.value.solicitudArchivos.filter(f => f.guid !== guid)
    }

    /* UI */

    /* Validations */
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetClassificationData)

    return {
      // Data
      classificationData,
      filesTableFields,
      customers,
      vendors,
      unidadesMedida,
      operationTypes,
      documentTypes,
      materialTypes,
      requestTypes,
      parts,
      refetchVendors,
      refetchDocumentTypes,
      refecthParts,
      partAlreadyExists,
      // Events
      onSubmit,
      onAddFile,
      onDeleteFile,

      // UI
      addingClassification,
      // Validations
      required,
      getValidationState,
      resetForm,
      refFormObserver,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
