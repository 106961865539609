<template>
  <div>
    <list-add-new
      :is-add-new-classification-sidebar-active.sync="isAddNewClassificationSidebarActive"
      @refresh-data="() => { refClassificationList.refreshData() }"
    />
    <list
      ref="refClassificationList"
      sort-by="SolicitudId"
      :sort-dir-desc="false"
      refetch-records-name="solicitudes"
      key-field="SolicitudId"
      report-name="SolicitudesClasificacion.xlsx"
      template-name="PlantillaClasificacion.xlsx"
      :actions="actions"
      :filters.sync="filters"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :refetch-records="fetchClassifications"
      :fetch-report="fetchExportExcel"
      :fetch-template="fetchExportTemplate"
      :upload-template="createImportExcel"
      upload-template-select-customer
      :delete-method="deleteClassification"
    >
      <template #cell(fechaHoraSolicitud)="data">
        <b-media v-if="stateTime.value" vertical-align="center">
          {{ data.item.fechaHoraSolicitud | formatDateTime }}
        </b-media>
        <b-media v-else vertical-align="center">
          {{ data.item.fechaHoraSolicitud | formatDate }}
        </b-media>
      </template>
      <template #cell(fechaHoraClasificacion)="data">
        <b-media v-if="stateTime.value" vertical-align="center">
          {{ data.item.fechaHoraClasificacion | formatDateTime }}
        </b-media>
        <b-media v-else vertical-align="center">
          {{ data.item.fechaHoraClasificacion | formatDate }}
        </b-media>
      </template>
      <template #cell(status)="data">
        <b-badge
          pill
          :variant="`light-${resolveClassificationStatusVariant(data.item)}`"
          class="text-capitalize"
        >
          <!-- {{ resolveClassificationStatusText(data.item) }} -->
          {{ data.item.clasificacionSolicitudEstado.nombre }}
        </b-badge>
      </template>
    </list>
  </div>
</template>

<script>
import {
  BBadge,
  BMedia,
} from 'bootstrap-vue'
import { saveAs } from 'file-saver'
import { ref } from '@vue/composition-api'
import classificationService from '@/services/classification.service'
import common from '@/libs/common'
import i18n from '@/libs/i18n'
import List from '@/components/List.vue'
import ListAddNew from './ListAddNew.vue'

export default {
  components: {
    BBadge,
    BMedia,
    List,
    ListAddNew,
  },
  setup() {
    /* Refs */
    const refClassificationList = ref(null)
    const isAddNewClassificationSidebarActive = ref(false)
    const dateTimeFormater = JSON.parse(localStorage.getItem('stateTime'))
    if (!dateTimeFormater.find(e => e.key === 'formatDateClasifications')) {
      dateTimeFormater.push({ key: 'formatDateClasifications', value: false })
      localStorage.setItem('stateTime', JSON.stringify(dateTimeFormater))
    }
    const stateTime = ref(dateTimeFormater.find(e => e.key === 'formatDateClasifications'))
    let actions = ref([])
    const changeStateTime = () => {
      stateTime.value.value = !stateTime.value.value
      localStorage.setItem('stateTime', JSON.stringify(dateTimeFormater))
      const index = actions.value.findIndex(e => e.name === 'dateAndHour')
      actions.value[index].value = stateTime.value.value
    }
    /* Services */
    const {
      fetchClassifications,
      fetchClassificationFiles,
      deleteClassification,
      fetchExportExcel,
      fetchExportTemplate,
      createImportExcel,
    } = classificationService()
    const { getUserName } = common()

    actions = ref([
      {
        label: i18n.t('classification.list.newRequest'),
        aclAction: 'create',
        aclResource: 'Clasificaciones',
        click: () => { isAddNewClassificationSidebarActive.value = true },
        icon: 'PlusCircleIcon',
      },
      {
        name: 'downloadReport',
        aclAction: 'read',
        aclResource: 'Clasificaciones',
      },
      {
        name: 'downloadTemplate',
        aclAction: 'create',
        aclResource: 'Clasificaciones',
      },
      {
        name: 'uploadTemplate',
        aclAction: 'create',
        aclResource: 'Clasificaciones',
      },
      {
        name: 'dateAndHour',
        aclAction: 'read',
        aclResource: 'AccionesMejora',
        icon: 'CalendarIcon',
        value: stateTime.value.value,
        click: changeStateTime,
      },
    ])

    const resolveClassificationStatusVariant = classification => {
      if (classification.clasificacionSolicitudEstadoId === 1) return 'warning'
      if (classification.clasificacionSolicitudEstadoId === 2) return 'success'
      if (classification.clasificacionSolicitudEstadoId === 3) return 'danger'
      return 'primary'
    }

    const classificationFilter = [
      i18n.t('classification.list.classified'),
      i18n.t('classification.list.classificationPending'),
    ]

    /* Events */
    const downloadFiles = item => {
      fetchClassificationFiles(item.solicitudId, data => {
        const file = new File([data], `Solicitiud_${item.solicitudId}_archivos.zip`)
        saveAs(file, `Solicitiud_${item.solicitudId}_archivos.zip`)
      })
    }
    const filters = ref([
      {
        type: 'date',
        name: 'fechaDesde',
        label: i18n.t('Components.List.Filters.DateFrom'),
        value: '',
      },
      {
        type: 'date',
        name: 'fechaHasta',
        label: i18n.t('Components.List.Filters.DateTo'),
        value: '',
      },
      {
        type: 'select',
        name: 'clienteId',
        label: i18n.t('classification.list.filterByCustomer'),
        value: '',
        options: [],
        domainName: 'Clientes',
      },
      {
        type: 'select',
        name: 'clasificacionSolicitudEstadoId',
        label: i18n.t('classification.list.filterByStatus'),
        value: '',
        options: [],
        domainName: 'ClasificacionSolicitudEstados',
      },
    ])

    const tableColumns = [
      {
        label: i18n.t('classification.list.columns.invoice'),
        key: 'solicitudId',
        sortable: true,
        sortKey: 'SolicitudId',
      },
      {
        label: i18n.t('classification.list.columns.clients'),
        key: 'cliente.nombre',
        sortable: true,
        sortKey: 'Cliente.Nombre',
      },
      {
        label: i18n.t('classification.list.columns.request'),
        key: 'fechaHoraSolicitud',
        sortable: true,
        sortKey: 'FechaHoraSolicitud',
      },
      {
        label: i18n.t('classification.list.columns.requestUser'),
        key: 'usuarioSolicitud',
        sortable: true,
        sortKey: 'UsuarioSolicitud',
      },
      {
        label: i18n.t('Routes.classification'),
        key: 'fechaHoraClasificacion',
        sortable: true,
        sortKey: 'FechaHoraClasificacion',
      },
      {
        label: i18n.t('classification.list.columns.userClassification'),
        key: 'usuarioClasificacion',
        sortable: true,
        sortKey: 'UsuarioClasificacion',
      },
      {
        label: i18n.t('classification.list.columns.documentKey'),
        key: 'documentoClave.clave',
        sortable: true,
        sortKey: '',
        sortableKey: 'NumeroParte',
      },
      {
        label: i18n.t('classification.list.columns.part'),
        key: 'numeroParte',
        sortable: true,
        sortKey: '',
        sortableKey: 'NumeroParte',
      },
      {
        label: i18n.t('classification.list.columns.status'),
        key: 'status',
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
      },
    ]

    const tableActions = ref([
      {
        name: 'edit',
        label: i18n.t('classification.list.showDetails'),
        aclAction: 'read',
        aclResource: 'Clasificaciones',
        routeName: 'apps-classification-edit',
        params: ['solicitudId'],
        icon: 'CodepenIcon',
      },
      {
        name: 'downloadFiles',
        label: i18n.t('classification.list.downloadAttachments'),
        aclAction: 'read',
        aclResource: 'Clasificaciones',
        click: downloadFiles,
        disabledCondition: item => !item.tieneArchivosAdjuntos,
        icon: 'ArrowDownCircleIcon',
      },
      {
        name: 'delete',
        label: i18n.t('Lists.Delete'),
        aclAction: 'read',
        aclResource: 'Clasificaciones',
        params: ['solicitudId'],
        disabledCondition: item => item.clasificacionSolicitudEstadoId === 2 || common().getUserName() !== item.usuarioSolicitud,
        icon: 'TrashIcon',
      },
    ])

    return {
      // Refs
      refClassificationList,
      stateTime,

      actions,
      filters,
      tableActions,
      tableColumns,

      fetchClassifications,
      deleteClassification,
      fetchExportExcel,
      fetchExportTemplate,
      createImportExcel,

      isAddNewClassificationSidebarActive,
      // resolveClassificationStatusText,
      resolveClassificationStatusVariant,
      classificationFilter,
      getUserName,
      // Events
      downloadFiles,
    }
  },
}
</script>

<style>

</style>
